import styled from 'styled-components';
import * as colours from '../../styles';

export const SuperHeading = styled.h1`
  text-align: center;
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  color: ${colours.black};
`;
export const Heading = styled.h3`
  color: ${colours.black};
`;
export const Subheading = styled.h4`
  color: ${colours.black};
`;
export const Opening = styled.p`
  margin-top: 1em;
  color: ${colours.black};
`;
export const Extract = styled.p`
  color: ${colours.black};
`;
export const Paragraph = styled.p`
  color: ${({ colour }) => colours[colour] || colours.black};
`;
export const MiniFlexText = styled.span`
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 1.5em;
  max-width: 10em;
  color: ${colours.actualWhite};
`;
export const Strong = styled.span`
  font-weight: bold;
  color: ${colours.black};
`;
export const Regular = styled.span`
  color: ${colours.black};
`;
export const MediaListHeading = styled.h5`
  margin: 1em;
  grid-area: m_heading;
  font-size: 1.5em;
  padding-bottom: 0.4em;
  margin: 0;
  color: ${colours.black};
  ${({ clickable }) => clickable && 'cursor: pointer;'}
`;
export const MediaListDetail = styled.p`
  grid-area: m_detail;
  font-size: 0.8em;
  padding-top: 0.4em;
  color: ${colours.black};
  font-weight: bold;
`;
export const MediaListContent = styled.p`
  display: block;
  grid-area: m_content;
  margin: 0;
  font-size: 1.2em;
  padding: 0.25em;
  color: ${colours.black};
`;
export const MediaListImageHolder = styled.div`
  grid-area: m_image;
`;
export const Underline = styled.div`
  grid-area: underline;
  height: 100%;
  width: 100%;
  background: ${`linear-gradient(to right, ${colours.theme}, ${colours.actualWhite})`};
`;
export const LineFade = styled.div`
  height: 0.2em;
  width: 100%;
  background: ${`linear-gradient(to right, ${colours.theme}, ${colours.actualWhite})`};
`;
export const TextList = styled.ul``;
export const TextListItem = styled.li`
  color: ${colours.black};
`;
export const Extra = styled.p`
  color: ${colours.black};
`;
export const FormLabel = styled.label`
  color: ${colours.black};
`;
export const FormInput = styled.input`
  border: 4px solid ${colours.colours[0]};
  border-radius: 0.5em;
  padding: 0.2em;
  margin: 0.2em;
  color: ${colours.black};
`;
export const FormTextarea = styled.textarea`
  border: 4px solid ${colours.colours[0]};
  border-radius: 0.5em;
  padding: 0.2em;
  margin: 0.2em;
  columns: 180;
  color: ${colours.black};
`;
export const SubmitButton = styled.button`
  color: ${colours.black};
  cursor: pointer;
`;
export const DownloadButton = styled.button`
  color: ${colours.black};
  cursor: pointer;
`;
export const FlexButton = styled.button`
  margin: 0.2em;
  color: ${colours.actualWhite};
  padding: 0.4em;
  transition: transform 100ms;
  background: transparent;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  ${({ isActiveType }) => isActiveType && 'text-decoration: underline;'}

  &:hover {
    transform: scale(1.1);
  }
`;
export const ButtonText = styled.span`
  color: ${colours.black};
`;
export const NavLink = styled.button`
  color: ${colours.black};
  cursor: pointer;
`;
export const CallLink = styled.button`
  color: ${colours.black};
  cursor: pointer;
`;
export const BannerLogo = styled.img`
  max-height: 85%;
  max-width: 16em;
  margin: 0;
`;
export const CarouselImageWrapper = styled.div`
  height: 20em;
  display: flex;
  justify-content: center;
`;
export const CarouselImage = styled.img`
  align-self: center;
  max-height: 100%;
  color: ${colours.black};
  width: ${({ imgWidth, imgReduction }) =>
    `${imgWidth - imgReduction}px !important`};
`;
export const BorderLine = styled.div`
  display: flex;
  align-content: center;
  width: 0.1em;
  height: 1.5em;
  background-color: ${colours.actualWhite};
  border-radius: 0.5em;
`;

export const ResourceTitle = styled.h2`
  grid-area: r_title;
  padding-left: 0.5em;
  margin: 0.5em;
  ${({ clickable }) => clickable && 'cursor: pointer;'}
`;

export const ResourceLink = styled.span`
  width: 8.5em;
`;

export const CornerLogo = styled.img`
  position: absolute;
  top: 0.2em;
  right: ${({ instep }) => `${instep * 1.75 + 0.2}em`};
  margin: 0;
`;

export const ExternalLink = styled.a``;
