import React, { Fragment } from 'react';
import AuthContext from '../contexts/AuthContext';
import { FormInput, MiniFlexText } from './styled-components/elements';

class CodeInput extends React.Component {
  state = {
    codeInput: '',
  };

  handleChangeCode = e => {
    this.setState({
      codeInput: e.target.value,
    });
  };

  handleCodeSubmit = (e, attemptCodeInput) => {
    if (e.key === 'Enter') {
      const { codeInput } = this.state;
      attemptCodeInput(codeInput);
    }
  };

  render() {
    const { codeInput } = this.state;
    return (
      <AuthContext.Consumer>
        {({ attemptCodeInput, codeInputError }) => (
          <Fragment>
            <FormInput
              type="text"
              value={codeInput}
              onChange={this.handleChangeCode}
              onKeyDown={e => this.handleCodeSubmit(e, attemptCodeInput)}
              placeholder="Enter member's code"
            />
            {codeInputError && (
              <MiniFlexText colour="actualWhite">
                ! - contact Kate if you need a reminder
              </MiniFlexText>
            )}
          </Fragment>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default CodeInput;
