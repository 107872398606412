import styled from 'styled-components';
import { black, white, theme } from '../../styles';

export const Banner = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme};
  padding: 5px;
  height: 100%;
`;

export const CentreFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CentreWide = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
`;

export const CentreAlign = styled.div`
  display: flex;
  align-items: center;
`;

export const ContentWrapper = styled.div`
  h1 {
    margin: 0;
  }
`;

export const Corner = styled.div``;

export const FlexCollection = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 100%;
`;

export const Footer = styled.footer``;

export const Form = styled.form`
  width: 100%;
`;

export const FullWidth = styled.div``;

export const GridItem = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
  /* width: 100%; */
`;

export const Main = styled.section``;

export const MarginExcluder = styled.div`
  width: ${({ width = 100 }) => `${width}%`};
  align-self: center;
`;

export const MaxiMediaGrid = styled.div`
  display: grid;
  grid-template: 2.5em 0.1em / 80%;
  grid-gap: 3%;
  grid-template-areas:
    'm_heading'
    'underline'
    'm_content';
  padding: 0.75em;
  padding-bottom: 1.5em;
  margin: 1em;
  max-width: 100%;
  transition-property: box-shadow;
  transition-duration: 500ms;

  &:hover {
    box-shadow: 5px 10px inset ${theme};
  }
`;

export const MediaList = styled.div`
  margin: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MediaListItem = styled.li``;

export const MiniMediaGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5em;
  padding-bottom: 0em;
  margin: 0.5em;
  margin-top: 3em;
  max-width: 100%;
  border-bottom: 4px solid ${theme};
`;

export const Navbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2em;
  height: 100%;
  background-color: ${theme};
`;

export const ParentGrid = styled.div`
  display: grid;
  grid-template-rows: ${({ gridRows = '100%' }) => gridRows};
  grid-template-columns: ${({ gridColumns = 1 }) => {
    const availWidth = gridColumns === 1 ? 100 : 98;
    return `${Math.floor(availWidth / gridColumns)}% `.repeat(gridColumns);
  }};
  grid-gap: 1.5%;
  position: relative;
  width: 100%;

  &::after {
    content: '';
    display: block;
    position: absolute;
    background: url('../img/stave-art.png');
    background-repeat: repeat-y;
    width: 100%;
    height: 120%;
    opacity: 0.1;
    z-index: -1;
  }
`;

export const ResourceWrapper = styled.div`
  padding: 0.1em;
`;

export const ResourceLinkArea = styled.div`
  grid-area: r_links;
  display: flex;
  flex-wrap: wrap;
`;

export const Site = styled.div`
  padding: 1em;
  background: ${white};
  display: flex;
  justify-content: center;
`;

export const UpdateGrid = styled.div`
  display: grid;
  grid-template-rows: 2em 1fr 1em;
  grid-gap: 2%;
  grid-template-areas:
    'm_heading'
    'm_content'
    'm_detail';
  padding: 0.5em;
  margin: 0.5em;
  width: 100%;
  border: 1px solid ${black};
  border-radius: 10px;
  padding-bottom: 1.5em;
  transition-property: box-shadow;
  transition-duration: 500ms;

  &:hover {
    box-shadow: 5px 0px inset ${theme};
  }
`;
