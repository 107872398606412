import React from 'react';
import { Link } from 'gatsby';
import CodeInput from './CodeInput';
import AuthContext from '../contexts/AuthContext';
import { FlexButton, BorderLine } from './styled-components/elements';
import {
  FlexCollection,
  Navbar,
  CentreFlex,
} from './styled-components/structures';

const Navigation = () => (
  <Navbar>
    <AuthContext.Consumer>
      {(authProps) => (
        <FlexCollection>
          <Link to="/">
            <FlexButton>Home</FlexButton>
          </Link>
          <CentreFlex>
            <BorderLine />
          </CentreFlex>
          <Link to="/about">
            <FlexButton>About</FlexButton>
          </Link>
          <CentreFlex>
            <BorderLine />
          </CentreFlex>
          <Link to="/choirs">
            <FlexButton>Choirs</FlexButton>
          </Link>
          <CentreFlex>
            <BorderLine />
          </CentreFlex>
          <Link to="/contact">
            <FlexButton>Contact</FlexButton>
          </Link>
          <CentreFlex>
            <BorderLine />
          </CentreFlex>
          <Link to="/fees">
            <FlexButton>Fees</FlexButton>
          </Link>
          <CentreFlex>
            <BorderLine />
          </CentreFlex>
          <Link to="/what-we-sing">
            <FlexButton>What We Sing</FlexButton>
          </Link>
          <CentreFlex>
            <BorderLine />
          </CentreFlex>
          {authProps.codeInputted ? (
            <>
              <Link to="/members/teach-tracks">
                <FlexButton>Teach Tracks</FlexButton>
              </Link>
              <CentreFlex>
                <BorderLine />
              </CentreFlex>
              <Link to="/members/resources">
                <FlexButton>Resources</FlexButton>
              </Link>
            </>
          ) : (
            <CodeInput />
          )}
        </FlexCollection>
      )}
    </AuthContext.Consumer>
  </Navbar>
);

export default Navigation;
