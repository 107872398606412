import React from 'react';
import PT from 'prop-types';
import MediaQuery from 'react-responsive';
import Helmet from 'react-helmet';
import Navigation from '../components/Navigation';
import AuthContext from '../contexts/AuthContext';
import {
  ParentGrid,
  Main,
  Banner,
  GridItem,
  Site,
} from '../components/styled-components/structures';
import {
  BannerLogo,
  CornerLogo,
  ExternalLink,
  Paragraph,
} from '../components/styled-components/elements';
import logo from '../../static/img/logo.png';
import instaLogo from '../../static/img/logo-insta.svg';
import twitLogo from '../../static/img/logo-twitter.svg';
import fbLogo from '../../static/img/logo-fb.svg';
import '../styles/index.css';

class TemplateWrapper extends React.Component {
  state = {
    available: true,
    passwordInput: '',
    password: 'Singer1',
  };

  handlePasswordInput = (passwordInput) => {
    this.setState({ passwordInput });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { passwordInput, password } = this.state;
    if (password === passwordInput) {
      this.setState({
        available: true,
      });
    }
  };

  render() {
    const { children } = this.props;
    const { available, passwordInput } = this.state;
    if (!available) {
      return (
        <>
          <h1>Site is under construction, please come back soon!</h1>
          <form onSubmit={this.handleSubmit}>
            <label htmlFor="password">
              Admin login
              <input
                name="password"
                value={passwordInput}
                onChange={(e) => this.handlePasswordInput(e.target.value)}
              />
            </label>
          </form>
        </>
      );
    }
    return (
      <AuthContext.Consumer>
        {({ initialDelay }) =>
          initialDelay ? (
            <Paragraph>Loading...</Paragraph>
          ) : (
            <MediaQuery maxWidth={375}>
              {(isSmall) => (
                <Site>
                  <ParentGrid gridRows={isSmall ? '10em 14em' : '8em 10em'}>
                    <Helmet title="Cheshire Community Choirs" />
                    <GridItem gridArea="1 / 1 / 2 / 2">
                      <Banner>
                        <BannerLogo
                          src={logo}
                          onError={(e) => console.log(e)}
                          alt="logo"
                        />
                        <ExternalLink
                          target="_blank"
                          href="https://www.youtube.com/channel/UCGK3zEMRngSFlETdGeKFMOA"
                        >
                          <CornerLogo src={instaLogo} instep={0} />
                        </ExternalLink>
                        <ExternalLink
                          target="_blank"
                          href="https://twitter.com/ChoirsKfirth"
                        >
                          <CornerLogo src={twitLogo} instep={1} />
                        </ExternalLink>
                        <ExternalLink
                          target="_blank"
                          href="https://www.facebook.com/cheshirecommunitychoirs/"
                        >
                          <CornerLogo src={fbLogo} instep={2} />
                        </ExternalLink>
                      </Banner>
                    </GridItem>
                    <GridItem gridArea="2 / 1 / 3 / 2">
                      <Navigation />
                    </GridItem>
                    <GridItem gridArea="3 / 1 / 4 / 2">
                      <Main>{children}</Main>
                    </GridItem>
                  </ParentGrid>
                </Site>
              )}
            </MediaQuery>
          )
        }
      </AuthContext.Consumer>
    );
  }
}

TemplateWrapper.propTypes = {
  children: PT.any.isRequired,
};

export default TemplateWrapper;
